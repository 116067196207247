<template>

	<!-- Authors Table Card -->
	<div>
		<loading :active.sync="isLoading" :is-full-page="true"></loading>
		<div v-if="alert.msg">
			<a-alert :message="alert.msg" :type="alert.type" closable></a-alert>
		</div>
		<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="12" class="mb-3">
						<h5 class="font-semibold m-0">Pilih Kota Asal</h5>
					</a-col>
					<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end" class="mb-3">

						<a-button @click="showModal = true" size="small" style="margin-right: 10px;">
							<a-icon type="funnel-plot" theme="outlined" />
						    Filter Data
						</a-button>
						<a-button type="primary" @click="onClickCreateData()" size="small">
							<a-icon type="plus" theme="outlined" />
						    Tambah Harga
						</a-button>
					</a-col>
					<span class="mb-3" v-for="(option, index) in options" :key="`option_${index}`">
						<a-button style="margin-right: 4px;" size="small" :type="option.id == selected_master ? 'primary' : ''" @click="selected_master = option.id">
							{{option.name.toUpperCase()}}
						</a-button>
					</span>
				</a-row>
			</template>
			<a-modal v-model="showModal" title="Filter Data" @ok="onSubmitFilter">
				<div class="mb-3">
		      		<a-input v-model="keyword" placeholder="Masukkan kata kunci ..." />
					<small>Nama kabupaten asal, nama kabupaten tujuan</small>
				</div>
				<div class="mb-3">
					<h6 class="font-regular">Filter service</h6>
					<div class="mb-1" v-for="(service, index) in services">
						<input type="checkbox" v-model="service_filter" :value="service.id" :key="`service_checkbox_${index}`" :id="`service_checkbox_${index}`">
						<label :for="`service_checkbox_${index}`"> {{service.name}}</label>
					</div><br/>
				</div>
		    </a-modal>
		    <a-modal v-model="showModalDelete" title="Hapus Data" @ok="onSubmitDelete">
				<div class="mb-3">
					<h6>Service {{selected_row.service}} dari "{{selected_row.from}}" ke "{{selected_row.to}}" {{selected_row.price_text}} </h6>
		      		<a-alert message="Anda akan menghapus harga yang dipilih. Apakah anda yakin?" type="error" />
				</div>
		    </a-modal>
		</a-card>
		<br/>
		<a-card :bordered="false" class="header-solid" :bodyStyle="{padding: 0,}">
			<a-collapse v-model="activeKey">
		      	<a-collapse-panel key="1" :header="renderCollapseHeader(prices)" v-for="(prices, index) in data" :key="`accordion_${index}`">
		      		<template #extra>
		      			<span title="Edit Harga" @click="(e) => handleClick(e, prices)">
		      				<a-icon type="edit" theme="outlined" />
		      			</span>
		      		</template>

		        	<a-table :columns="columns" :data-source="prices.prices" :pagination="false">

						<template slot="to" slot-scope="to">
							<div class="author-info">
								<h6 class="m-0">{{ to }}</h6>
							</div>
						</template>
						<template slot="service" slot-scope="service">
							<div class="author-info">
								<h6 class="m-0">{{ service }}</h6>
							</div>
						</template>

						<!-- <template slot="editBtn" slot-scope="row">
							<a-button type="primary" size="small" :data-id="row.key" @click="onUpdate(row)">
								<a-icon type="edit" theme="outlined" />
								Edit
							</a-button>&nbsp;
							<a-button type="danger" size="small" ghost :data-id="row.key" @click="onDelete(row)">
								<a-icon type="delete" theme="outlined" />
								Hapus
							</a-button>
						</template> -->

					</a-table>
		      	</a-collapse-panel>
		    </a-collapse>
		</a-card>
	</div>
	<!-- / Authors Table Card -->

</template>

<script>

		// "Authors" table list of columns and their properties.
	const table1Columns = [
		{
			title: 'KOTA TUJUAN',
			dataIndex: 'to',
			scopedSlots: { customRender: 'to' },
		},
		{
			title: 'SERVICE',
			dataIndex: 'service',
			scopedSlots: { customRender: 'service' },
		},
		{
			title: 'HARGA',
			dataIndex: 'price_text',
			scopedSlots: { customRender: 'price' },
		},
		// {
		// 	title: '',
		// 	scopedSlots: { customRender: 'editBtn' },
		// 	width: 250,
		// },
	];

	import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
	import { processMessage, setInitialValue } from '../helpers';
	import LokasiAutoComplete from '../components/LokasiAutoComplete';

	export default ({
		data() {
			return {
				// Active button for the "Authors" table's card header radio button group.
				isLoading: false,
				authorsHeaderBtns: 'all',
				data: [],
				keyword: '',
				service_filter: [],
				columns: table1Columns,
				showModal: false,
				showModalCreate: false,
				showModalDelete: false,
				selected_row: {},
				alert: {
					type: '',
					msg: ''
				},
				form: {
					name: ''
				},
				validation: {
					name: ''
				},
				options: [],
				selected_master: '',
				activeKey: 1,
				first_load: false,
				services: []
			}
		},
		components: {
			Loading, LokasiAutoComplete
		},
		mounted(){
			this.retrieveServiceList()
		},
		watch: {
			selected_master(newValue, oldValue){
				this.retrievePriceList()
			}
		},
	 	methods: {
	 		populateAddress(){
	 			this.$http.get(process.env.VUE_APP_API + '/regency')
		      	.then((res) => {

			        let result = []

			        if(res.data.data.length){
				        res.data.data.map((option, index) => {
				          	if(!option.hasOwnProperty('value')){
				            	option.value = option.id.toString()
				          	}

				          	if(!option.hasOwnProperty('text')){
				            	option.text = option.search
				          	}

				          	result.push(option)
				        })

				        this.$store.dispatch('setAddresses', result)
			        }

			        console.log(result)
			    })
	 		},
	 		renderCollapseHeader(prices){
	 			if(prices.prices_count == 0){
	 				return `Dari ${prices.location} - ${prices.name} tidak memiliki harga`
	 			}

	 			return `Dari ${prices.location} - ${prices.name} memiliki ${prices.prices_count} harga`
	 		},
	 		retrieveServiceList(){
	 			this.isLoading = true
	 			this.$http.get(process.env.VUE_APP_API + '/service')
				.then((res) => {
					this.services = res.data.data

					this.services.map((service, index) => {
						this.service_filter.push(service.id)
					})

					this.retrieveLocationList()
				})
				.catch(err => {
					this.alert = processMessage(err.response)
				})
				.finally(() => {
					this.isLoading = false
				})
	 		},
	 		retrieveLocationList(){
	 			this.$http.get(process.env.VUE_APP_API + '/location')
				.then((res) => {
					this.options = res.data.data

					if(this.options.length && !this.first_load){
						this.selected_master = this.options[0].id
						this.first_load = true
					}

					setInitialValue(this.alert)
				})
				.catch(err => {
					this.alert = processMessage(err.response)
				})
	 		},
	 		retrievePriceList(){
	 			this.isLoading = true
	 			this.$http.get(process.env.VUE_APP_API + '/location/'+this.selected_master+'/price?keyword=' + this.keyword + '&service=' + this.service_filter)
				.then((res) => {
					this.data = res.data.data
				})
				.catch(err => {
					this.alert = processMessage(err.response)
				})
				.finally(() => {
					this.isLoading = false
				})
	 		},
			onSubmitFilter(e){
      			this.showModal = false;
				this.retrievePriceList();
			},
			onDelete(row){
				this.showModalDelete = true 
				this.selected_row = row
			},
			onClickCreateNew(){
				setInitialValue(this.validation)

				this.showModalCreate = true;
				this.form.name = ''
				this.selected_row = ''
				this.type = 'create'
			},
			onUpdate(row){
				setInitialValue(this.validation)

				this.selected_row = row
				this.form.name = this.selected_row.name
				this.type = 'update'
				this.showModalCreate = true
			},
			onSubmitDelete(){
				this.$http.delete(process.env.VUE_APP_API + '/price/' + this.selected_row.id)
				.then((res) => {
					this.alert = processMessage(res)
					this.showModalDelete = false
					this.retrievePriceList()
				})
				.catch(err => {
					this.alert = processMessage(err.response)
				})
			},
			onSubmitForm(){

			},
			onChangeLokasi(value){
				console.log(value)
			},
			handleClick(e, prices){
				event.stopPropagation();
				// console.log(prices);
				this.$router.push({name: 'Ubah Harga', params: {
					id: prices.id
				}})
			},
			onClickCreateData(){
				return this.$router.push({name: 'Tambah Harga'})
			}
		}
	})

</script>